import React, { useEffect } from "react"
import { navigate } from "@reach/router"

const NotFoundPage: React.FC = () => {
  useEffect(() => {
    navigate("/not-found/")
  }, [])
  return <></>
}

export default NotFoundPage
